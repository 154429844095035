/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
require('@/assets/css/style.css');

import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

// new Vue({
//   render: h => h(App),
// }).$mount('#app2')

// window.$ = window.jQuery = require('jquery');

$('#menu_btn').on('click', function () {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
    $(this).children('img').attr('src', 'img/menu-open.png');
    $('#menu').removeClass('open');
    $('#collapseExample').collapse({
      toggle: false
    })
  } else {
    $(this).addClass('active');
    $(this).children('img').attr('src', 'img/menu-close.png');
    $('#menu').addClass('open');
  }
});
$(window).on('load scroll', function () {
  $('.discover').css('background-position', 'center top -' + parallaxFunc(200, 300, '.discover', 1274) + 'px');
  $('.parallax').css('background-position', 'center top -' + parallaxFunc(300, 400, '.parallax', 1274) + 'px');
  $('.discover-mobile').css('background-position', 'center top -' + parallaxFunc(0, 5, '.discover-mobile', 1274) + 'px');
  $('.parallax-mobile').css('background-position', 'center top -' + parallaxFunc(0, 5, '.parallax-mobile', 1274) + 'px');
});

/**
 * パララックス要素の現在の値を返す
 * @param num moveStart   (required) パララックスで動かす要素の開始地点での値
 * @param num moveEnd     (required) パララックスで動かす要素の終了地点での値
 * @param num target (required) パララックスで動かすclass
 * @param num size   (required) パララックスで動かす画像サイズ
 * @return num
 */
function parallaxFunc(moveStart, moveEnd, target, size) {
  var winScroll = $(window).scrollTop();
  var winHeight = $(window).height();
  var th = $(target).position();
  var scrollStart = th.top;
  var scrollEnd = th.top + size;
  // スクロール判定の基準位置
  var scrollPos = winScroll + winHeight;

  // 現在の値の取得
  var currentVal;
  // スクロール範囲に到達していない場合
  if (scrollPos < scrollStart) {
    currentVal = moveStart;

    // スクロール範囲を過ぎている場合
  } else if (scrollPos > scrollEnd) {
    currentVal = moveEnd;

    // スクロール範囲内の場合
  } else {
    // スクロール範囲
    var scrollRange = scrollEnd - scrollStart;
    // 移動範囲
    var moveRange = Math.abs(moveEnd - moveStart);
    // 進行方向
    var direction = moveStart < moveEnd ? 1 : -1;
    // 進捗率1%あたり進む距離
    var moveRate = moveRange / 100 * direction;
    // 現在の進捗率
    var progressRate = (scrollPos - scrollStart) / scrollRange * 100;

    currentVal = moveStart + progressRate * moveRate;
  }
  return currentVal;
}
var mySwiper = new Swiper('.swiper-container', {
  centeredSlides: true,
  effect: 'fade',
  loop: true,
  simulateTouch: false,
  autoplay: {
    delay: 5000,
  },
});
var giftSwiper = new Swiper('.gift-swiper', { loop: true, simulateTouch: false, });
var lineSwiper = new Swiper('.line-swiper', {
  loop: true,
  simulateTouch: false,
  autoplay: {
    delay: 5000,
  },
});
var sableSwiper = new Swiper('.sable-swiper', {
  loop: true,
  simulateTouch: false,
  autoplay: {
    delay: 5000,
  },
});
// ページ内スクロール用
$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var windowWidth = $(window).width();
    var position;
    if ($(window).width() > 992) {
      position = target.offset().top;
    } else {
      position = target.offset().top - 56;
    }
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    if ($('#menu_btn').hasClass('active')) {
      $('#menu_btn').removeClass('active');
      $('#menu_btn').children('img').attr('src', 'img/menu-open.png');
      $('#menu').removeClass('open');
    }
    return false;
  });
});
// アコーディオン用
$('#collapseExample').on('show.bs.collapse', function () {
  $('#accordion').children('img').attr('src', 'img/mobile-link-close.svg');
})
$('#collapseExample').on('hide.bs.collapse', function () {
  $('#accordion').children('img').attr('src', 'img/mobile-rink-circle.svg');
})